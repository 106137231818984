@import url('grapesjs/dist/css/grapes.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css');

:root {
  --gjs-left-width: 0px;
  --gjs-canvas-top: 0px;
  --gjs-arrow-color: var(--ff-color-text-base-darkest);
}

.gjs-editor .fa {
  line-height: 21px !important;
  font: normal normal normal 14px/1 FontAwesome !important;
}

.gjs-blocks-c {
  gap: var(--space-less);
}

.gjs-toolbar-items .gjs-toolbar-item:first-child {
  display: none;
}

.gjs-block {
  width: 88px;
  height: 88px;
  border: var(--border-base-lighter);
  box-shadow: 0px 1px 0px 0px #2021271a;
  padding: var(--space-small) var(--space-less);
  gap: var(--space-tiny);
  border-radius: var(--space-small);
  justify-content: center;
  margin: 0;

  .gjs-block__media {
    margin: 0;
    color: var(--ff-color-text-base-darkest);
  }
  .gjs-block-label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: var(--font-family-default);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--ff-color-text-base-darkest);
    gap: var(--space-less);

    &::after {
      width: 14px;
      height: 8px;
      display: block;
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgcng9IjEiIGZpbGw9IiNCOUJGQzciLz4KPHJlY3QgeD0iMC41IiB5PSI1IiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiByeD0iMSIgZmlsbD0iI0I5QkZDNyIvPgo8cmVjdCB4PSI1LjUiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIHJ4PSIxIiBmaWxsPSIjQjlCRkM3Ii8+CjxyZWN0IHg9IjUuNSIgeT0iNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgcng9IjEiIGZpbGw9IiNCOUJGQzciLz4KPHJlY3QgeD0iMTAuNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgcng9IjEiIGZpbGw9IiNCOUJGQzciLz4KPHJlY3QgeD0iMTAuNSIgeT0iNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgcng9IjEiIGZpbGw9IiNCOUJGQzciLz4KPC9zdmc+Cg==');
    }
  }
}

.gjs-one-bg {
  background-color: white !important;
}

.gjs-two-color {
  color: var(--ff-color-text-base-darkest) !important;
}

.gjs-sm-clear {
  display: none;
}

.gjs-field {
  border: var(--border-base-light);
  box-shadow: var(--shadow-inset);
  border: var(--border-base-light);
  background: var(--ff-bg-base-lightest);

  input,
  select {
    height: 30px;
    color: var(--ff-color-text-base-darkest);
    padding: var(--space-tiny) var(--space-less);
  }

  .gjs-input-unit {
    padding: 8px 5px;
    color: var(--ff-color-text-info-darkest);
  }

  &.gjs-field-integer {
    .gjs-field-units {
      right: 0;
    }
    .gjs-field-arrows {
      display: none;
    }
  }
}

.gjs-sm-label {
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--ff-color-text-base-darkest);
  margin-bottom: var(--space-less);
}

.gjs-sm-field {
  background: transparent;
  border: 0;
  border-radius: 0;
}

.gjs-sm-properties {
  padding: 0;
  margin: 0;
}

.gjs-sm-sector {
  margin-bottom: var(--space-default);

  > .gjs-sm-properties {
    padding: 0 var(--space-default);
    margin: 0;
  }
}

.gjs-sm-open {
  border-bottom: 0;
}

.gjs-sm-sector-title,
.gjs-label {
  background: transparent;
  border-bottom: 0;
  padding: 0;
  color: var(--ff-color-text-base-darkest);
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: var(--space-less);
  margin-left: var(--space-default);
}

.gjs-sm-properties {
  display: flex !important;
  gap: 16px;
}

.gjs-sm-sector-caret {
  display: none;
}

.gjs-sm-property--full {
  border-bottom: var(--border-base-lighter) !important;
  padding-bottom: var(--space-default) !important;
  background-color: transparent !important;
  padding: 0;

  .gjs-sm-composite {
    background: transparent;
    border: transparent !important;
  }

  .gjs-fields {
    display: flex;
  }
}

.gjs-sm-property {
  padding: 0;
  width: 44%;
}

.gjs-sm-sector-label {
  margin: 0;
}

.gjs-rte-toolbar {
  position: fixed;
  top: 55px !important;
  left: 401px !important;
  padding: 0 var(--space-small);
  min-height: 50px;
  width: calc(100% - 401px);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  border-left: 0;
}

.gjs-mdl-dialog {
  text-shadow: none;
  color: var(--ff-color-text-base-darkest);

  .gjs-am-assets-cont,
  .gjs-am-file-uploader > form {
    background-color: white;
  }

  .gjs-am-name {
    width: 93%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
